import { fontFamily } from '@ballz-app/chakra';
import { HStack, Image, Link, Text, VStack } from '@chakra-ui/react';

import { CTAButton } from './CTAButton';

export const Footer = () => {
  return (
    <VStack
      mt={{ base: '50px', xxs: '54px' }}
      pb={{ base: '80px', xxs: '59px' }}
      spacing={0}
    >
      <CTAButton footer={true} />
      <HStack mt={{ base: '50px', xxs: '125px' }} spacing="24px">
        <Link isExternal href="https://t.me/ballzgameTG">
          <Image
            src="/images/home/circle-telegram.svg"
            alt="circle-telegram"
            boxSize={{ base: '40px', xxs: '80px' }}
          />
        </Link>
        <Link isExternal href="https://twitter.com/theballzgame">
          <Image
            src="/images/home/circle-x.svg"
            alt="circle-telegram"
            boxSize={{ base: '40px', xxs: '80px' }}
          />
        </Link>
      </HStack>
      <Text
        width={{ base: '276px', xxs: '497px' }}
        mt={{ base: '36px', xxs: '54px' }}
        fontFamily={fontFamily.luckiestGuy}
        fontSize={{ base: '20px', xxs: '36px' }}
        color="#3B254C"
        lineHeight="89%"
        textAlign="center"
      >
        Keep your eyes on the ballZ. follow us for updates!
      </Text>
      <Image
        mt={{ base: '80px', xxs: '112px' }}
        src="/images/home/logo.svg"
        alt="logo"
        width={{ base: '149px', xxs: '213px' }}
        height={{ base: '113', xxs: '160px' }}
      />
      <Text
        mt={{ base: '36px', xxs: '54px' }}
        fontFamily={fontFamily.luckiestGuy}
        fontSize={{ base: '20px', xxs: '30px' }}
        color="#3B254C"
        lineHeight="89%"
        width={{ base: '273px', xxs: 'unset' }}
        textAlign="center"
      >
        @2024 by Ballz of Steel. All rights reserved.
      </Text>
    </VStack>
  );
};
