import { fontFamily } from '@ballz-app/chakra';
import { Box, HStack, Image, Link, VStack } from '@chakra-ui/react';
import { StrokeText } from '@ui/components/StrokeText';

import { CTAButton } from './CTAButton';

export const Banner = () => {
  return (
    <VStack
      backgroundImage={{
        base: "url('/images/home/banner-bg-mobile.svg')",
        xxs: "url('/images/home/banner-bg.svg')",
      }}
      minWidth="100%"
      height={{ base: '655px', xxs: '1195px' }}
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
    >
      <Image
        mt={{ base: '63px', xxs: '88px' }}
        src="images/home/big-logo.svg"
        alt="logo"
        width={{ base: '220px', xxs: '423px' }}
        height={{ base: '252px', xxs: '486px' }}
      />
      <StrokeText
        mt={{ base: '13px', xxs: '34px' }}
        fontFamily={fontFamily.luckiestGuy}
        fontSize={{ base: '36px', xxs: '80px' }}
        color="#FFB800"
        lineHeight="89%"
        strokeWidth={{ base: '8px', xxs: '12px' }}
        strokeColor="#3B254C"
        maxWidth="100vw"
        textAlign="center"
      >
        BIGGER ISN&apos;T BETTER
      </StrokeText>
      <HStack
        mt={{ base: '16px', xxs: 'unset' }}
        spacing={{ base: '24px', xxs: '73px' }}
      >
        <Link isExternal href="https://t.me/ballzgameTG">
          <Image
            src="/images/home/telegram.svg"
            alt="telegram"
            width={{ base: '24px', xxs: '48px' }}
            height={{ base: '20px', xxs: '43px' }}
            cursor="pointer"
          />
        </Link>
        <Link isExternal href="https://twitter.com/theballzgame">
          <Image
            src="/images/home/x.svg"
            alt="x"
            width={{ base: '24px', xxs: '40px' }}
            height={{ base: '22px', xxs: '38px' }}
          />
        </Link>
      </HStack>
      <Box mt={{ base: '30px', xxs: '90px' }}>
        <CTAButton />
      </Box>
    </VStack>
  );
};
