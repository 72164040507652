import { fontFamily } from '@ballz-app/chakra';
import {
  HStack,
  Image,
  Show,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { StrokeText } from '@ui/components/StrokeText';

interface StepPros {
  background: string;
  bannerBackground: string;
  bannerText: string;
  bannerTextColor: string;
  content: string;
  questionMark?: boolean;
}

const Step = ({
  background,
  bannerBackground,
  bannerText,
  bannerTextColor,
  content,
  questionMark,
}: StepPros) => {
  return (
    <VStack
      width="214px"
      height="237px"
      borderRadius="8px"
      background={background}
      boxShadow="5px 5px 0px 0px #3B254C"
      border="2px solid #3B254C"
    >
      <VStack
        width="100%"
        height="77px"
        background={bannerBackground}
        borderRadius="6px"
        justifyContent="center"
      >
        <Text
          fontFamily={fontFamily.luckiestGuy}
          fontSize="42px"
          lineHeight="89%"
          color={bannerTextColor}
          position="relative"
          top="6px"
        >
          {bannerText}
        </Text>
      </VStack>
      <VStack height="100%" pt="32px">
        {questionMark ? (
          <StrokeText
            fontFamily={fontFamily.luckiestGuy}
            fontSize="60px"
            color="#F8478B"
            lineHeight="89%"
            strokeWidth="13px"
            strokeColor="#3B254C"
            position="relative"
            top="12px"
          >
            ??
          </StrokeText>
        ) : (
          <Text
            fontSize="24px"
            fontWeight={800}
            lineHeight="21px"
            color="#3B254C"
            textAlign="center"
            width="180px"
          >
            {content}
          </Text>
        )}
      </VStack>
    </VStack>
  );
};

const MobileStep = ({
  background,
  bannerBackground,
  bannerText,
  bannerTextColor,
  content,
  questionMark,
}: StepPros) => {
  return (
    <HStack
      width="342px"
      height="95px"
      borderRadius="10px"
      background={background}
      boxShadow="4px 4px 0px 0px #3B254C;"
      border="2px solid #3B254C"
    >
      <VStack
        width="129px"
        height="100%"
        background={bannerBackground}
        borderRadius="6px"
        justifyContent="center"
      >
        <Text
          fontFamily={fontFamily.luckiestGuy}
          fontSize="30px"
          lineHeight="89%"
          color={bannerTextColor}
          position="relative"
          top="6px"
        >
          {bannerText}
        </Text>
      </VStack>
      <VStack height="100%" justifyContent="center" pl="15px">
        {questionMark ? (
          <StrokeText
            fontFamily={fontFamily.luckiestGuy}
            fontSize="50px"
            color="#F8478B"
            lineHeight="89%"
            strokeWidth="4px"
            strokeColor="#3B254C"
            position="relative"
            top="6px"
          >
            ??
          </StrokeText>
        ) : (
          <Text
            fontSize="17px"
            fontWeight={800}
            lineHeight="17px"
            color="#3B254C"
            width="165px"
            textAlign="start"
          >
            {content}
          </Text>
        )}
      </VStack>
    </HStack>
  );
};

export const How = () => {
  const imageUrl = useBreakpointValue({
    base: '/images/home/how-bg-mobile.png',
    xxs: '/images/home/how-bg.png',
  });

  return (
    <VStack mt={{ base: '182px', xxs: '114px' }} position="relative">
      <VStack
        width={{ base: '100vw', xxs: '1208px' }}
        height={{ base: '664px', xxs: '505px' }}
        overflow="hidden"
        justifyContent="center"
      >
        <Image
          src={imageUrl}
          alt="Example Image"
          width={{ base: '1048px', xxs: '100%' }}
          height="100%"
          objectFit="cover"
          position="absolute"
        />
        <Show above="xxs">
          <VStack position="relative" spacing="0px">
            <Image
              src="/images/home/line.svg"
              alt="line"
              position="absolute"
              top="90px"
            />
            <HStack spacing="37px" zIndex={1}>
              <Step
                background="#B7FEF6"
                bannerBackground="#47FCE9"
                bannerText="STEP 1"
                bannerTextColor="#1A7D72"
                content="Check in daily and complete tasks to claim ballz"
              />
              <Step
                background="#FFD7E6"
                bannerBackground="#FF90BB"
                bannerText="STEP 2"
                bannerTextColor="#823C57"
                content="Drop your ballz and score points"
              />
              <Step
                background="#E4D8FF"
                bannerBackground="#A276FF"
                bannerText="STEP 3"
                bannerTextColor="#513390"
                content="Climb your way up the ranks"
              />
              <Step
                background="#FFF2DA"
                bannerBackground="#FFA800"
                bannerText="STEP 4"
                bannerTextColor="#704A00"
                content=""
                questionMark={true}
              />
            </HStack>
          </VStack>
        </Show>
        <Show below="xxs">
          <VStack position="relative" spacing="0px">
            <Image
              src="/images/home/line-mobile.svg"
              alt="line"
              position="absolute"
              top="90px"
            />
            <VStack spacing="30px" zIndex={1}>
              <MobileStep
                background="#B7FEF6"
                bannerBackground="#47FCE9"
                bannerText="STEP 1"
                bannerTextColor="#1A7D72"
                content="Check in daily and complete tasks to claim ballz"
              />
              <MobileStep
                background="#FFD7E6"
                bannerBackground="#FF90BB"
                bannerText="STEP 2"
                bannerTextColor="#823C57"
                content="Drop your ballz and score points"
              />
              <MobileStep
                background="#E4D8FF"
                bannerBackground="#A276FF"
                bannerText="STEP 3"
                bannerTextColor="#513390"
                content="Climb your way up the ranks"
              />
              <MobileStep
                background="#FFF2DA"
                bannerBackground="#FFA800"
                bannerText="STEP 4"
                bannerTextColor="#704A00"
                content=""
                questionMark={true}
              />
            </VStack>
          </VStack>
        </Show>
      </VStack>
      <StrokeText
        position="absolute"
        top={{ base: '-100px', xxs: '-5px' }}
        width={{ base: '336px', xxs: 'unset' }}
        fontFamily={fontFamily.luckiestGuy}
        fontSize={{ base: '50px', xxs: '57px' }}
        color="#A276FF"
        lineHeight="89%"
        strokeWidth="12px"
        strokeColor="#3B254C"
        textAlign="center"
      >
        how does ballz of steel work?
      </StrokeText>
    </VStack>
  );
};
