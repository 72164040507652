import { fontFamily } from '@ballz-app/chakra';
import { Box, Flex, HStack, Image, Show, VStack } from '@chakra-ui/react';
import { StrokeText } from '@ui/components/StrokeText';

export const Roadmap = () => {
  return (
    <VStack>
      <StrokeText
        fontFamily={fontFamily.luckiestGuy}
        fontSize={{ base: '50px', xxs: '60px', md: '70px' }}
        color="#F8478B"
        lineHeight="89%"
        strokeWidth={{ base: '11px', xxs: '14px' }}
        strokeColor="#3B254C"
      >
        ROADMAP
      </StrokeText>
      <Show above="xxs">
        <HStack
          mt="82px"
          width="780px"
          position="relative"
          justifyContent="space-between"
        >
          <VStack spacing="27px">
            <Image src="/images/home/logo.svg" alt="logo" zIndex={1} />
            <StrokeText
              fontFamily={fontFamily.luckiestGuy}
              fontSize="54px"
              color="#FFF"
              lineHeight="89%"
              strokeWidth="13px"
              strokeColor="#3B254C"
            >
              LAUNCH
            </StrokeText>
          </VStack>
          <Box position="absolute" top="78px" left="70px">
            <Box position="relative">
              <Image src="/images/home/bar.svg" alt="bar" />
              <Image
                src="/images/home/ballz.svg"
                alt="ballz"
                position="absolute"
                top="-85px"
                left="250px"
              />
            </Box>
          </Box>
          <VStack spacing="55px">
            <Image
              src="/images/home/airdrop.svg"
              alt="airdrop"
              zIndex={1}
              position="relative"
              top="-10px"
            />
            <StrokeText
              position="relative"
              left="40px"
              fontFamily={fontFamily.luckiestGuy}
              fontSize="60px"
              color="#FFF"
              lineHeight="89%"
              strokeWidth="13px"
              strokeColor="#3B254C"
            >
              ????
            </StrokeText>
          </VStack>
        </HStack>
      </Show>
      <Show below="xxs">
        <Flex
          mt="39px"
          height="494px"
          direction="column"
          position="relative"
          justifyContent="space-between"
        >
          <VStack spacing="15px" zIndex={1}>
            <Image
              src="/images/home/logo.svg"
              alt="logo"
              zIndex={1}
              width="131px"
              height="101px"
            />
            <StrokeText
              fontFamily={fontFamily.luckiestGuy}
              fontSize="28px"
              color="#FFF"
              lineHeight="89%"
              strokeWidth="10px"
              strokeColor="#3B254C"
            >
              LAUNCH
            </StrokeText>
          </VStack>
          <Image
            src="/images/home/bar-mobile.svg"
            alt="bar"
            position="absolute"
            top="120px"
            left="20px"
          />
          <VStack spacing="10px" zIndex={1}>
            <Image
              src="/images/home/airdrop.svg"
              alt="airdrop"
              width="124px"
              height="104px"
              position="relative"
              left="-25px"
            />
            <StrokeText
              position="relative"
              fontFamily={fontFamily.luckiestGuy}
              fontSize="45px"
              color="#FFF"
              lineHeight="89%"
              strokeWidth="10px"
              strokeColor="#3B254C"
            >
              ????
            </StrokeText>
          </VStack>
        </Flex>
      </Show>
    </VStack>
  );
};
