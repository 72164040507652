import merge from 'lodash-es/merge';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import type { NextSeoProps } from 'next-seo/lib/types';

const SITE_NAME = process.env.APP_DOMAIN;
const BASE_URL = process.env.APP_DOMAIN;
const AUTHOR = '@theballzgame';

interface SEOTemplateProps {
  title: string;
  locale?: string;
  path?: string;
  description: string;
  moreConfig?: NextSeoProps;
  textLimit?: number;
}

const defaultSEOConfig: NextSeoProps = {
  noindex: false,
  nofollow: false,
  openGraph: {
    site_name: SITE_NAME,
    type: 'website',
    locale: 'en_US', // Add locale information
    article: {
      authors: [AUTHOR],
      publishedTime: new Date(1727251200000).toISOString(), // Add publication time
    },
    images: [
      {
        url: `${BASE_URL}/home/game-preview.gif`, // Add a default Open Graph image
        width: 590,
        height: 590,
        alt: 'Ballz game preview',
      },
    ],
  },
  twitter: {
    handle: AUTHOR,
    site: AUTHOR,
    cardType: 'summary',
  },
  additionalMetaTags: [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1',
    },
    {
      name: 'theme-color',
      content: '#47FCE9', // Add theme color for mobile browsers
    },
  ],
};

export const SEOTemplate = ({
  locale,
  path,
  title,
  description,
  moreConfig = {},
  textLimit = 300,
}: SEOTemplateProps) => {
  const { asPath } = useRouter();
  // Remove the query in asPath
  path = path ?? asPath.split('?')[0];
  const url = `${BASE_URL}${path}`;

  const languageAlternates = [
    { hrefLang: 'en', href: url },
    { hrefLang: 'x-default', href: url },
  ];

  description =
    description.length > textLimit
      ? description.slice(0, textLimit) + '...'
      : description;

  const customConfig: NextSeoProps = {
    title,
    titleTemplate: title,
    description,
    canonical: url,
    languageAlternates,
    openGraph: {
      locale,
      url,
      title: title,
      description,
    },
  };
  const seoConfig = merge({}, defaultSEOConfig, customConfig, moreConfig);
  return <NextSeo {...seoConfig} />;
};
