import { fontFamily } from '@ballz-app/chakra';
import {
  Image,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { StrokeText } from '@ui/components/StrokeText';

export const About = () => {
  const imageUrl = useBreakpointValue({
    base: '/images/home/about-bg-mobile.png',
    xxs: '/images/home/about-bg.png',
  });

  return (
    <VStack mt={{ base: '161px', xxs: '124px' }} position="relative">
      <VStack
        width={{ base: '100vw', xxs: '1208px' }}
        height={{ base: '625px', xxs: '570px' }}
        overflow="hidden"
        justifyContent="center"
      >
        <Image
          src={imageUrl}
          alt="Example Image"
          width={{ base: '1048px', xxs: '100%' }}
          height="100%"
          objectFit="cover"
          position="absolute"
        />
        <Stack
          direction={{ base: 'column', xxs: 'row' }}
          alignItems="center"
          spacing={{ base: '18px', xxs: '30px' }}
          zIndex={1}
        >
          <Image
            src="/images/home/game.svg"
            alt="game"
            width={{ base: '200px', xxs: '381px' }}
            height={{ base: '216px', xxs: '406px' }}
          />
          <VStack
            alignItems={{ base: 'center', xxs: 'flex-start' }}
            spacing="0px"
          >
            <Text
              width={{ base: '250px', xxs: '604px' }}
              fontSize={{ base: '16px', xxs: '28px' }}
              fontWeight={800}
              color="#3B254C"
              lineHeight="normal"
              textAlign={{ base: 'center', xxs: 'unset' }}
            >
              Bigger isn&apos;t better when it comes to Ballz of Steel, you just
              need more. Drop those ballz to rack up the points and climb the
              ranks!
            </Text>
            <StrokeText
              mt={{ base: '32px', xxs: '61px' }}
              width={{ base: '200px', xxs: 'unset' }}
              fontFamily={fontFamily.luckiestGuy}
              fontSize={{ base: '25px', xxs: '36px' }}
              color="#47FCE9"
              lineHeight="89%"
              strokeWidth={{ base: '5px', xxs: '22px' }}
              strokeColor="#3B254C"
              textAlign={{ base: 'center', xxs: 'unset' }}
            >
              WHAT ARE THE POINTS FOR?
            </StrokeText>
            <Text
              mt={{ base: '19px', xxs: '40px' }}
              width={{ base: '250px', xxs: '604px' }}
              fontSize={{ base: '16px', xxs: '28px' }}
              fontWeight={800}
              color="#3B254C"
              lineHeight="normal"
              textAlign={{ base: 'center', xxs: 'unset' }}
            >
              Your points will come in handy...Stay tuned, your ballz are worth
              more than you think!
            </Text>
          </VStack>
        </Stack>
      </VStack>
      <StrokeText
        position="absolute"
        top={{ base: '-50px', xxs: '-25px' }}
        width={{ base: '306px', xxs: 'unset' }}
        fontFamily={fontFamily.luckiestGuy}
        fontSize={{ base: '50px', xxs: '57px' }}
        color="#FFA800"
        lineHeight="89%"
        strokeWidth={{ base: '10px', xxs: '11px' }}
        strokeColor="#3B254C"
        textAlign="center"
      >
        about ballz of steel
      </StrokeText>
      <Image
        src="/images/home/ballz_v2.gif"
        alt="ballz"
        position="absolute"
        top={{ base: '-175px', xxs: '-180px' }}
        right={{ base: 'unset', xxs: '200px' }}
        zIndex={1}
        width={{ base: '65px', xxs: '124px' }}
        height={{ base: '120px', xxs: '230px' }}
      />
    </VStack>
  );
};
