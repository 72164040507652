import { fontFamily } from '@ballz-app/chakra';
import { Box, Link } from '@chakra-ui/react';
import { StrokeText } from '@ui/components/StrokeText';

interface CTAButtonProps {
  footer?: boolean;
}

export const CTAButton = ({ footer = false }: CTAButtonProps) => {
  return (
    <Link isExternal href="https://t.me/ballzofsteelbot">
      <Box
        padding={{ base: ' 16px 20px 10px 20px', xxs: '23px 29px 14px 29px' }}
        width={{ base: '244px', xxs: '500px' }}
        borderRadius={{ base: '16px', xxs: '29px' }}
        border="4px solid #3B254C"
        background="#F8478B"
        boxShadow={
          footer ? '3px 3px 0px 0px #3B254C' : '4px 4px 0px 0px #47FCE9'
        }
        textAlign="center"
      >
        <StrokeText
          color={'#47FCE9'}
          fontSize={{ base: '25px', xxs: '45px' }}
          lineHeight="89%"
          strokeWidth="6px"
          strokeColor={'#3B254C'}
          fontWeight="400"
          fontFamily={fontFamily.heading}
        >
          DROP BALLZ NOW
        </StrokeText>
      </Box>
    </Link>
  );
};
